<style scoped>
	div,input,textarea{box-sizing: border-box;}
	.page_box_add_community{display: flex;flex-direction: column;overflow: hidden;}
	.page_box_body{overflow: auto;flex: 1;}
	.page_box_footer{padding: 10px 10px 0 10px;text-align: right;}
	/* 表单 */
	
	.z_form_item_row{padding: 10px ;}
	.z_form_item{flex: 1;display: flex;flex-direction: row;align-items:center ;}
	.z_form_item_label{width: 100px;text-align: right;padding-right: 10px;}
	.z_form_item_value{flex: 1;}
	.z_form input{height: 30px;width: 100%;}
	.z_form textarea{height: 80px;width: 100%;padding: 5px;}
</style>
<style>
	.z_form .el-form-item{margin-bottom: 0;}
	.z_form .el-form-item__content{margin-left: 0!important;}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header"></div>
		<div class="page_box_body">
			<div class="z_form">
				<el-form label-width="80px" :model="form" :rules="rules" ref="form">
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">姓名</div>
									<div class="z_form_item_value">
										<el-input placeholder="必填" type="text" v-model="form.name"></el-input>
									</div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">电话</div>
								<div class="z_form_item_value">
									<el-input placeholder="必填" v-model="form.phone"></el-input>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">身份证号码</div>
								<div class="z_form_item_value">
									<el-input placeholder="选填" v-model="form.id_card"></el-input>
								</div>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">房屋关系</div>
								<div class="z_form_item_value">
									<el-select v-model="form.owner_type" placeholder="请选择">
										<el-option label="业主" value="1"></el-option>
										<el-option label="亲属" value="2"></el-option>
										<el-option label="租户" value="3"></el-option>
									</el-select>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						
					</el-row>
				</el-form>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button @click="closeDialog">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="onSubmit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props:['pageParams'],
		data() {
			return {
				form: {
					name: '',
					phone: '',
					id_card: '',
					owner_type: ''
				},
				rules: null,
				btnSaveLoading: false,
			}
		},
		created: function() {
			_this = this;
		},
		methods: {
			// _ *** 关闭弹窗
			closeDialog(){
				_this.$emit('childrenEvent', {
					type:'close'
				});
			},
			// _** 提交保存
			onSubmit() {
				_this.btnSaveLoading = true;
				var params = _this.form;
				params.room_id = _this.pageParams.room.id;
				_this._postApi('/wy/owner/create', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type:'add'
						});
						_this.$message({
							message: '添加成功！',
							type: 'success',
							duration: 1000,
							onClose: function() {}
						});
						
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
